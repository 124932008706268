<template>
  <el-card>
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      :rules="rules"
      label-width="120px"
    >
      <b-row>
        <b-col md="6">
          <el-form-item
            label="字段类型"
            prop="classId"
            :rules="[ { required: true, message: '类型不能为空', trigger: 'change' }]"
          >
            <el-cascader
              v-model="form.classId"
              :options="classOptions"
              style="width: 100%;"
              :show-all-levels="false"
              popper-class="cas-class"
              :props="{checkStrictly: false,emitPath:false, expandTrigger: 'hover', value: 'id', label: 'label' }"
              @change="getClassFieldTree(1)"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="字段名称"
            prop="fieldId"
            :rules="[ { required: true, message: '字段不能为空', trigger: 'change' }]"
          >
            <el-select
              v-model="form.fieldId"
              filterable
              placeholder="请选择字段名称"
            >
              <el-option
                v-for="(item,index) in fileOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="所属业务"
            prop="productId"
          >
            <el-select
              v-model="form.productId"
              placeholder="请选择业务"
              @change="form.assetsId='';gueryAssetsOptions()"
            >
              <el-option
                v-for="(item,index) in productList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="所在资产"
            prop="assetsId"
            :rules="[ { required: true, message: '资产不能为空', trigger: 'change' }]"
          >
            <el-select
              v-model="form.assetsId"
              class="mr10"
              placeholder="请选择资产"
            >
              <el-option
                v-for="(item,index) in assestList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item
            label="存储情况"
            style="margin-bottom: 0"
          >
            <div
              class="flex-start mt-2"
            >
              <el-form-item
                class="mr10 table-form-item el-form-item__content-left0"
                label="库名"
                prop="saveDatabase"
                :rules="[ { required: true, message: '库名不能为空', trigger: 'change' }]"
              >
                <el-input
                  v-model="form.saveDatabase"
                  v-input-filter
                  oninput="if( this.value.length > 50 )  this.value = this.value.slice(0,50)"
                  placeholder="请输入库名"
                />
              </el-form-item>
              <el-form-item
                class="mr10 table-form-item el-form-item__content-left0"
                label="表名"
                prop="saveTable"
                :rules="[ { required: true, message: '表名不能为空', trigger: 'change' }]"
              >
                <el-input
                  v-model="form.saveTable"
                  v-input-filter
                  oninput="if( this.value.length > 50 )  this.value = this.value.slice(0,50)"
                  placeholder="请输入表名"
                />
              </el-form-item>
              <el-form-item
                class="mr10 table-form-item el-form-item__content-left0"
                prop="saveTableField"
                :rules="[ { required: true, message: '字段名称不能为空', trigger: 'change' }]"
                label="字段名称"
              >
                <el-input
                  v-model="form.saveTableField"
                  v-input-filter
                  oninput="if( this.value.length > 50 )  this.value = this.value.slice(0,50)"
                  placeholder="请输入表字段名称"
                />
              </el-form-item>
              <el-form-item
                class="mr10 table-form-item el-form-item__content-left0"
                prop="saveTableField"
                :rules="[ { required: true, message: '数据量不能为空', trigger: 'change' }]"
                label="数据量"
              >
                <el-input
                  v-model.number="form.saveCount"
                  type="number"
                  oninput="if( this.value.length > 15 )  this.value = this.value.slice(0,15)"
                  onkeyup="this.value=this.value.replace(/[^\-?\d.]/g,'')"
                  placeholder="请输入数据量(条)"
                />
              </el-form-item>
            </div>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="数据保护方式"
          >
            <el-select
              v-model="form.dataSecureWay"
              class="mr10"
              placeholder="请选择数据保护方式"
            >
              <el-option
                v-for="(item,index) in dataSecureWayOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item
            label="数据保护说明"
          >
            <el-input
              v-model="form.dataSecureWayExplain"
              type="textarea"
              :autosize="autosize"
              placeholder="请输入数据保护说明"
            />
          </el-form-item>
        </b-col>
      </b-row>

    </el-form>
    <div class="dialog-footer text-center">
      <el-button @click="back">
        返 回
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-card>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { QueryAssetsOptions } from '@/api/enterprise/assest'
import {
  getAssetsDataInfo,
  saveOrUpdateAssetsPrivacyField,
  selectFiledBySource,
} from '@/api/dataMap/dataMapProcess'
import { GetClassOptions } from '@/api/system/privacy/privacy'
import { getAllProduct } from '@/api/enterprise/product'

export default {
  // components: {
  //   bTreeView,
  // },

  data() {
    return {
      assestList: [],
      productList: [],
      fileOptions: [],
      dataSecureWayOptions: [
        { label: '无', value: 0 },
        { label: '加密', value: 1 },
        { label: '脱敏', value: 2 },
        { label: '其他保护措施', value: 3 },
      ],
      loading: false,
      form: {
        id: 0,
        productId: undefined,
        assetsId: undefined,
        classId: undefined,
        fieldId: undefined,
        saveDatabase: '',
        saveTable: '',
        saveTableField: '',
        saveCount: 0,
        dataSecureWay: 0,
        dataSecureWayExplain: '',
      },
      rules: {},
      dialogVisible: false,
      classOptions: [],
      groupOptions: [],
      selectFields: [],
      treeOptions: [],
    }
  },
  created() {
    this.getClassOptions()
    this.getProuctOptions()
    this.gueryAssetsOptions()
    if (this.$route.query.id) {
      this.form.id = Number(this.$route.query.id)
      this.getInfo(this.form.id)
    }
  },
  mounted() {
    this.navActiveInit(1, '个人信息资产', '数据智能')
  },
  destroyed() {
    this.navActiveInit(2, '个人信息资产', '数据智能', '/infoAssest')
  },
  methods: {
    getInfo(id) {
      getAssetsDataInfo(id).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
          if (this.form.productId === 0) {
            this.form.productId = undefined
          }
          this.getClassFieldTree()
        } else {
          error(resData.msg)
        }
      })
    },
    getProuctOptions() {
      getAllProduct()
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.productList = resData.data
          }
        })
    },
    getClassOptions() {
      GetClassOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.classOptions = resData.data
        }
      })
    },
    getClassFieldTree(type) {
      selectFiledBySource(0, 0, 0, this.form.classId).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.fileOptions = resData.data
        }
      })
      if (type === 1) {
        this.form.fieldId = undefined
      }
    },
    gueryAssetsOptions() {
      QueryAssetsOptions(0, this.form.productId, '')
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.assestList = resData.data
          }
        })
    },
    back() {
      sessionStorage.setItem('activeName', '3')
      this.$router.push('/infoAssest')
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.form.fieldId) {
            error('请选择字段')
            return
          }
          this.loading = true
          this.form.assetsId = this.form.assetsId ? this.form.assetsId : 0
          saveOrUpdateAssetsPrivacyField(this.form).then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.back()
            } else {
              error(resData.msg)
            }
          })
          this.loading = false
        }
      })
    },
    dialogClose() {
      this.dialogVisible = false
      this.listQuery = {
        search: '',
        gradeId: '',
        classId: '',
        assetsId: '',
      }
    },
  },
}
</script>

<style lang="scss">
.table-form-item {
  position: relative;
  .el-form-item__label {
    position: absolute;
    top: -28px;
    left: 0;
    text-align: left;
  }
}
.el-form-item__content-left0 {
  .el-form-item__content {
    margin-left: 0 !important;
  }
}
</style>
